import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './interceptor';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [previousLocation, setPreviousLocation] = useState<string | null>(null);
  const [progress, setProgress] = React.useState(10);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setPreviousLocation(location.pathname);
        navigate('/login', { replace: true });
        setIsLoading(false);
        return;
      }
  
      try {
        const response = await axiosInstance.get(`/auth/verify-token/${token}`);
        if (response.status === 200) {
          setIsAuthenticated(true);
          setIsAdmin(response.data.is_admin);
        } else {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        localStorage.removeItem('token');
        setPreviousLocation(location.pathname);
        navigate('/login', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };
  
    verifyToken();
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (isAuthenticated && isAdmin !== null) {
      if (isAdmin && !location.pathname.startsWith('/admin')) {
        navigate('/admin/dashboard', { replace: true });
      } else if (!isAdmin && location.pathname.startsWith('/admin')) {
        navigate('/', { replace: true });
      } else if (previousLocation && previousLocation !== '/login' && location.pathname !== previousLocation) {
        navigate(previousLocation, { replace: true });
      }
    }
  }, [isAuthenticated, location.pathname, navigate, isAdmin, previousLocation]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>; 
};

export default AuthProvider;
