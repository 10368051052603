import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Button, Modal, TextField, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { getTyreParams } from '../../../services/core-api';

const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 500,
   bgcolor: 'background.paper',
   boxShadow: 24,
   p: 4,
 };

const TireSearch = () => {
  const [selectedSeason, setSelectedSeason] =React.useState<string | null>(null);
  const [widths, setWidths] = React.useState<string[]>([]);
  const [selectedWidth, setSelectedWidth] = React.useState<string | null>(null);
  const [heights, setHeights] = React.useState<string[]>([]);
  const [selectedHeight, setSelectedHeight] = React.useState<string | null>(null);
  const [dimensions, setDimensions] = React.useState<string[]>([]);
  const [selectedDimension, setSelectedDimension] = React.useState<string | null>(null);
  const [manufacturers, setManufacturers] = React.useState<string[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isLoadingTire, setIsLoadingTire] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    season: string,
  ) => {
    setSelectedSeason(season);
  };

  const handleSubmitTireData = () => {
   setIsLoadingTire(true);
   let params = [];

    if (selectedSeason) params.push(`season=${selectedSeason}`);
    if (selectedWidth) params.push(`width=${selectedWidth}`);
    if (selectedHeight) params.push(`height=${selectedHeight}`);
    if (selectedDimension) params.push(`dimension=${selectedDimension}`);
    if (selectedManufacturer) params.push(`manufacturer=${selectedManufacturer}`);

    const url = `/search-tyres?${params.join('&')}`;
    window.location.href = url;
 }

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="body1" align="left" sx={{ fontSize: "13px", color: theme.palette.text.primary, m: 1, display: 'flex', alignItems: 'center' }}>
         Auto tires search by size <InfoOutlinedIcon onClick={handleOpen} sx={{ ml: 1, cursor: 'pointer' }} />
      </Typography>
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            style={{'width':'100%'}}
            src='./tire_size_numbers.jpg'
            alt='Tires Size Chart'
          />
        </Box>
      </Modal>
    </div>
   <Box sx={{ display: 'flex', gap: 1 }}>
      <ToggleButtonGroup
      disabled
      value={selectedSeason}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
         float: 'left',
         backgroundColor: theme.palette.primary.light,
         '& .MuiToggleButton-root.Mui-selected': {
            color: theme.palette.secondary.main,
            borderLeft: '1px solid #dcdcdc !important',
            backgroundColor: theme.palette.primary.light,
         },
      }}
      >
      <ToggleButton value="krejt">
         All Season
      </ToggleButton>
      <ToggleButton value="VERE">
         <WbSunnyIcon sx={{ marginRight: 1 }}/> Summer
      </ToggleButton>
      <ToggleButton value="DIMER">
         <AcUnitIcon sx={{ marginRight: 1 }}/> Winter
      </ToggleButton>
      </ToggleButtonGroup>
   </Box>
   <Typography variant="body1" align="left" sx={{ fontSize: "13px", color: theme.palette.text.primary, m: 1, display: 'flex', alignItems: 'center' }}>
      Select Width, Height and Diameter
   </Typography>
   <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
      <Autocomplete
        disabled
        options={widths}
        onChange={(event, value) => setSelectedWidth(value)}
        value={selectedWidth}
        renderInput={(params) => 
        <TextField {...params} 
            label="Width" 
            InputLabelProps={{
               ...params.InputLabelProps,
               sx: {
                 color: theme.palette.text.primary,
                 fontSize: "13px",
                 height: "100%",
                 top: "-4px",
                 transform: "translate(24px, 12px), scale(1) !important",
               },
             }}
             InputProps={{
               ...params.InputProps,
               sx: {
                 borderRadius: 0, // Adjust the height as needed
                 height: "40px",
               }}}
         />}
        sx={{
         flex: 1,
         width: "100%",
         height: "40px",
         backgroundColor: "#fff",
         ".MuiInputBase-input": {
           height: "40px",
           marginTop: "0px",
           marginBottom: "0px",
           paddingTop: "0px",
           paddingBottom: "0px",
         },
       }}
      />
      <Autocomplete
        disabled={!selectedWidth}
        options={heights}
        onChange={(event, value) => setSelectedHeight(value)}
        value={selectedHeight}
        renderInput={(params) => 
        <TextField {...params} 
            label="Height" 
            InputLabelProps={{
               ...params.InputLabelProps,
               sx: {
                 color: theme.palette.text.primary,
                 fontSize: "13px",
                 height: "100%",
                 top: "-4px",
               },
             }}
             InputProps={{
               ...params.InputProps,
               sx: {
                 borderRadius: 0, // Adjust the height as needed
                 height: "40px",
               }}}
         />}
        sx={{
         flex: 1,
         width: "100%",
         height: "40px",
         backgroundColor: "#fff",
         ".MuiInputBase-input": {
           height: "40px",
           marginTop: "0px",
           marginBottom: "0px",
           paddingTop: "0px",
           paddingBottom: "0px",
         },
       }}
      />
      <Autocomplete
        disabled={!selectedHeight}
        options={dimensions}
        onChange={(event, value) => setSelectedDimension(value)}
        value={selectedDimension}
        renderInput={(params) => 
        <TextField {...params} 
            label="Diameter" 
            InputLabelProps={{
               ...params.InputLabelProps,
               sx: {
                 color: theme.palette.text.primary,
                 fontSize: "13px",
                 height: "100%",
                 top: "-4px",
               },
             }}
             InputProps={{
               ...params.InputProps,
               sx: {
                 borderRadius: 0, // Adjust the height as needed
                 height: "40px",
               }}}
         />}
        sx={{
         flex: 1,
         width: "100%",
         height: "40px",
         backgroundColor: "#fff",
         ".MuiInputBase-input": {
           height: "40px",
           marginTop: "0px",
           marginBottom: "0px",
           paddingTop: "0px",
           paddingBottom: "0px",
         },
       }}
      />
    </Box>
    <Typography variant="body1" align="left" sx={{ fontSize: "13px", color: theme.palette.text.primary, m: 1, display: 'flex', alignItems: 'center' }}>
      Select Brands and Speed rating
   </Typography>
   <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
      <Autocomplete
        disabled
        options={manufacturers}
        onChange={(event, value) => setSelectedManufacturer(value)}
        value={selectedManufacturer}
        renderInput={(params) => 
        <TextField {...params} 
            label="Brands" 
            InputLabelProps={{
               ...params.InputLabelProps,
               sx: {
                 color: theme.palette.text.primary,
                 fontSize: "13px",
                 height: "100%",
                 top: "-4px",
                 transform: "translate(24px, 12px), scale(1) !important",
               },
             }}
             InputProps={{
               ...params.InputProps,
               sx: {
                 borderRadius: 0, // Adjust the height as needed
                 height: "40px",
               }}}
         />}
        sx={{
         flex: 1,
         width: "100%",
         height: "40px",
         backgroundColor: "#fff",
         ".MuiInputBase-input": {
           height: "40px",
           marginTop: "0px",
           marginBottom: "0px",
           paddingTop: "0px",
           paddingBottom: "0px",
         },
       }}
      />
    </Box>
    <Box sx={{marginTop: 1}}>
    {isLoadingTire ? (
          <LoadingButton loading variant="contained"
              sx={{ 
                height: '38px',
                width: "100%",
                borderRadius:0,
                alignItems: 'center',  // Center items vertically
                justifyContent: 'flex-end'
              }} 
              >
                Submit
              </LoadingButton>
        ) :(
        <>
          <Button
          disabled={!selectedDimension && !selectedManufacturer}
            sx={{
              width: "100%",
              textTransform: 'capitalize',
              borderRadius:0,
              backgroundColor: "secondary.main",
              color: "text.secondary",
              ":hover": {
                backgroundColor: "secondary.main",
              }
            }}
            onClick={handleSubmitTireData}>
            Search
          </Button>
        </>
        )}
    </Box>
    </ThemeProvider>
  );
}

export default TireSearch;