import * as React from 'react';
import Header from "../../components/common/header/Header";
import {
  Box,
  Grid,
  Stack,
  Link,
  Typography,
  Breadcrumbs,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../theme";
import dayjs, { Dayjs } from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClientSideMenu from "./ClientSideMenu";

import { getUserBalance, getUserBalanceTotal } from '../../services/core-api';

const BalancePage = () => {
  const [balanceId, setBalanceId] = React.useState(0);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Dayjs | null>(null);
  const [selectedToDate, setSelectedToDate] = React.useState<Dayjs | null>(null);
  const [rows, setRows] = React.useState<any[]>([]);
  const [kod, setKod] = React.useState('');
  const [situationBefore, setSituationBefore] = React.useState(0);
  const [situationAfter, setSituationAfter] = React.useState(0);

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
    
    if (authUser) {
        const user = JSON.parse(authUser);
        setBalanceId(user.erp_id);
    }
}, [balanceId]);

  const fetchClientRecords = async () => {
    if (!balanceId ) return;
    try {
      const params = {
        clientID: balanceId,
        startDate: selectedFromDate
        ? selectedFromDate.format("DD/MM/YYYY").replace("/", "*")
        : "",
        endDate: selectedToDate ? selectedToDate.format("DD/MM/YYYY").replace("/", "*") : "",
      };
  
      const response = await getUserBalance(params);
      if (response) {
        setRows(response);
      }

      const responseTotal = await getUserBalanceTotal(params);
      if (responseTotal) {
        setKod(responseTotal[0].KOD);
        setSituationBefore(responseTotal[0].VLEFTA);
        setSituationAfter(responseTotal[0].VLEFTAMV);
      }
  
    } catch (error) {
      console.error("Error fetching client records:", error);
    }
  };

  React.useEffect(() => {
    fetchClientRecords();
  }, [balanceId, selectedFromDate, selectedToDate]);

  const handleFromDateChange = (date: Dayjs | null) => {
    setSelectedFromDate(date);
  };

  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
  };

  const formatDate = (dateString: any) => {
    const [date, time] = dateString.split('T'); // Split the string into date and time parts
  
    // Split the date into [year, month, day]
    const [year, month, day] = date.split('-');
  
    // Split the time into [hours, minutes, seconds]
    const [hours, minutes] = time.split(':');
  
    // Format as "day-month-year hours:minutes"
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}`;
  
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <Header />
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ClientSideMenu />
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{ paddingTop: "3px", paddingLeft: "5px" }}
                    >
                      <Breadcrumbs
                        separator={
                          <NavigateNextIcon
                            sx={{ color: "black" }}
                            fontSize="small"
                          />
                        }
                        aria-label="breadcrumb"
                      >
                        <Link
                          underline="hover"
                          key="2"
                          sx={{
                            color: theme.palette.text.primary,
                            ml: 1,
                            fontWeight: 400,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                          href="/"
                        >
                          Home
                        </Link>
                        <Typography
                          key="3"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                        >
                          Balance
                        </Typography>
                      </Breadcrumbs>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 1 }}>
                      <DatePicker
                        label="Start Date"
                        format="DD/MM/YYYY"
                        value={selectedFromDate}
                        onChange={handleFromDateChange}
                        sx={{
                          bgcolor: "#fff",
                          mr: 1,
                          width: "160px",
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                            borderRadius: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 0,
                          },
                          "& .MuiInputBase-input": {
                            height: "30px",
                            padding: "0 14px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#5d5d5d",
                            top: "-10px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                        }}
                      />
                      <DatePicker
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={selectedToDate}
                        onChange={handleToDateChange}
                        sx={{
                          bgcolor: "#fff",
                          mr: 1,
                          width: "160px",
                          "& .MuiOutlinedInput-root": {
                            height: "30px",
                            borderRadius: 0,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 0,
                          },
                          "& .MuiInputBase-input": {
                            height: "30px",
                            padding: "0 14px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#5d5d5d",
                            top: "-10px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ overflowX: "auto" }}>
                {/* Table */}
                
                <TableContainer component={Paper} sx={{ boxShadow: "none", minWidth: "800px", pb:4 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontFamily: "Inter", fontWeight: 600 }}>KOD</TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Inter", fontWeight: 600 }}>Date & Time</TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Inter", fontWeight: 600 }}>Description</TableCell>
                        <TableCell align="left" sx={{ fontFamily: "Inter", fontWeight: 600 }}>Invoice number</TableCell>
                        <TableCell align="right" sx={{ fontFamily: "Inter", fontWeight: 600 }}>VLEFTA</TableCell>
                        <TableCell align="right" sx={{ fontFamily: "Inter", fontWeight: 600 }}>VLEFTAMV</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length > 0 && rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.KOD}</TableCell>
                          <TableCell align="left">{formatDate(row.DTFAT)}</TableCell>
                          <TableCell align="left">{row.PERSHKRIM}</TableCell>
                          <TableCell align='left'>{row.NRFAT}</TableCell>
                          <TableCell align="right">{row.VLEFTA}</TableCell>
                          <TableCell align="right">{row.VLEFTAMV}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container spacing={2} sx={{borderBottom: "1px solid #f1f1f1"}}>
                  <Grid item xs={4}>
                    <Typography 
                      variant="body1"
                      sx={{
                        float: "left",
                        color: "#e10101",
                        padding: "10px",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 600
                      }}
                    >
                      KOD: {kod}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography 
                      variant="body1"
                      sx={{
                        float: "left",
                        color: "#e10101",
                        marginTop: "10px",
                        marginLeft: "10px",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 600
                      }}
                    >
                      Situation before: {situationBefore}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography 
                      variant="body1"
                      sx={{
                        float: "left",
                        color: "#e10101",
                        marginTop: "10px",
                        marginLeft: "10px",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 600
                      }}
                    >
                      Situation after: {situationAfter}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    </LocalizationProvider>
  );
};

export default BalancePage;
