import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import * as React from "react";
import theme from "../../../theme";
import { LoadingButton } from "@mui/lab";
import { getBatteryParams } from "../../../services/core-api";
import { Count } from "../../../interfaces/DataSupplierFacetCounts";
import { Criteria } from "../../../interfaces/Criteria";

const BatterySearch = () => {
  const frontendURL = process.env.REACT_APP_FRONTEND_URL;
  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [brands, setBrands] = React.useState<Count[]>([]);
  const [openBrand, setOpenBrand] = React.useState(false);
  const [selectedBrand, setSelectedBrand] = React.useState<Count|null>(null);

  const [openAmpher, setOpenApmher] = React.useState(false);
  const [amphers, setAmphers] = React.useState<Criteria[]|[]>([]);
  const loadingAmpher = openAmpher && amphers.length === 0;
  const [selectedAmpher, setSelectedAmpher] = React.useState<Criteria|null>(null);
  const [page, setPage] = React.useState(1);

  const [error, setError] = React.useState('');

  React.useEffect(() => {

    (async () => {
      try {
        const response = await getBatteryParams();
        if (response){
          setBrands(response.dataSupplierFacets);
          setAmphers(response.criteriaFacets);
          setError('');
        }
      } catch (error){
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(String(error));
        }
      }
    })()
  },[]);


  const handleBrandChange = async(
    event: React.ChangeEvent<{}>,
    brand: Count | null
  ) => {
    if (brand) {
      setSelectedBrand(brand);
      setOpenBrand(false);
    }
  }

  const handleAmpherChange = async (
    event: React.ChangeEvent<{}>,
    ampher: Criteria | null
  ) => {
    if (ampher) {
      setSelectedAmpher(ampher);
      setOpenApmher(false);
    }
  }


  const handleSubmitData = () => {
    setIsLoadingData(true);
    const dynamicUrl = `${frontendURL}/search-products?category=3465?`
    + `${selectedBrand ? `&brand=${selectedBrand.dataSupplierId}` : ''}`
    + `${selectedAmpher ? `&criteria=68%3A${selectedAmpher.formattedValue}` : ''}`;

  window.location.href = dynamicUrl;
  };

  return (
    <Box>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "13px",
          marginTop: { xs: 2, sm: 11.5 },
          color: theme.palette.text.primary,
          display: "flex",
          alignItems: "center",
        }}
      >
        Select Battery Brands
      </Typography>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <Autocomplete
          options={brands}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option.mfrName}
            </Box>
          )}
          getOptionLabel={(option) =>( option.mfrName)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Battery Brand"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px"
                },
              }}
            />
          )}
          value={selectedBrand}
          onChange={handleBrandChange}
          sx={{
            flex: 1,
            marginTop: "15px",
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
      </Box>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "13px",
          color: theme.palette.text.primary,
          m: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        Select Battery Ah
      </Typography>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <Autocomplete
          options={amphers}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option.formattedValue}
            </Box>
          )}
          getOptionLabel={(option) =>( option.formattedValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Battery Ah"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px"
                },
              }}
            />
          )}
          value={selectedAmpher}
          onChange={handleAmpherChange}
          sx={{
            flex: 1,
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
      </Box>
      <Box sx={{ marginTop: 1 }}>
        {isLoadingData ? (
          <LoadingButton
            loading
            variant="contained"
            sx={{
              height: "38px",
              width: "100%",
              alignItems: "center", // Center items vertically
              justifyContent: "flex-end",
            }}
          >
            Submit
          </LoadingButton>
        ) : (
          <>
            <Button
              disabled={!selectedBrand && !selectedAmpher}
              sx={{
                width: "100%",
                textTransform: "capitalize",
                borderRadius:0,
                backgroundColor: "secondary.main",
                ":hover": {
                  backgroundColor: "secondary.main",
                },
                color: "text.secondary",
              }}
              onClick={handleSubmitData}
            >
              Search
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BatterySearch;
